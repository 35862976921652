// extracted by mini-css-extract-plugin
export var header = "styles-module--header--D6h52";
export var heroLogoContainer = "styles-module--heroLogoContainer--liph9";
export var headerLogoDiv = "styles-module--headerLogoDiv--7Y86Q";
export var logo = "styles-module--logo--FQMF6";
export var logoSmallText = "styles-module--logoSmallText--4j+PK";
export var ctaButton = "styles-module--ctaButton--WDN4C";
export var menuButton = "styles-module--menuButton--uat6L";
export var menuButtonDot = "styles-module--menuButtonDot--2BgWR";
export var openedMenu = "styles-module--openedMenu--LKtAn";
export var closeMenuButton = "styles-module--closeMenuButton--CPuvh";
export var readMoreButton = "styles-module--readMoreButton--D9bnT";