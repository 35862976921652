import { faEnvelope, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React from 'react';
import { ScrollTrigger, Tween } from 'react-gsap';
import fizjomedicaLogo from '../../../images/Fizjomedica-logo.svg';

import * as styles from './styles.module.scss';

const SCROLL_TRIGGER_PROPS = { start: 'top center', end: 'top center', scrub: 2 };

export default function ContactSection() {
  return (
    <section id="contact" className={styles.contactSection}>
      <div className="wrapper">
        <ScrollTrigger {...SCROLL_TRIGGER_PROPS} trigger=".contactTitle">
          <Tween from={{ opacity: 0, y: -20 }} duration={2}>
            <h2 className={'contactTitle'}>Kontakt</h2>
          </Tween>
        </ScrollTrigger>

        <div className={styles.contactInfoWrapper}>
          <ScrollTrigger {...SCROLL_TRIGGER_PROPS} trigger={'.contactTitle'}>
            <Tween from={{ opacity: 0, x: -120 }} duration={2}>
              <div className={styles.contactInfo}>
                <div className={styles.addressName}>
                  <img src={fizjomedicaLogo} alt="Fizjomedica" className={styles.companyLogo} />
                  <h4 className={styles.companyName}>Centrum Rehabilitacyjno-Medyczne FIZJOMEDICA</h4>
                </div>
                <a href="tel:+48600989249">
                  <p>
                    <FontAwesomeIcon icon={faPhone} /> +48 600 989 249
                  </p>
                </a>
                <a href="mailto:biernacki.fizjoterapia@gmail.com">
                  <p>
                    <FontAwesomeIcon icon={faEnvelope} /> biernacki.fizjoterapia@gmail.com
                  </p>
                </a>
                <a
                  href="https://www.google.com/maps/place/Zar%C4%99by+7,+43-110+Tychy/@50.0909935,18.9965665,17z/data=!3m1!4b1!4m6!3m5!1s0x4716b83d5087beff:0x559113bd46daff9e!8m2!3d50.0909935!4d18.9991414!16s%2Fg%2F11c258tqm2?entry=ttu"
                  target="_blank"
                  rel="noreferrer">
                  <p>
                    <FontAwesomeIcon icon={faMapMarkerAlt} /> ul. Zaręby 7i, 43-100 Tychy (Ryneczek osiedla Z)
                  </p>
                </a>
              </div>
            </Tween>
          </ScrollTrigger>
          <ScrollTrigger {...SCROLL_TRIGGER_PROPS} trigger={'.contactTitle'}>
            <Tween from={{ opacity: 0, x: 120 }} duration={2}>
              <iframe
                className={styles.googleMap}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2559.7486058813124!2d18.996566476881426!3d50.090993471525856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716b83d5087beff%3A0x559113bd46daff9e!2sZar%C4%99by%207%2C%2043-110%20Tychy!5e0!3m2!1spl!2spl!4v1693693858741!5m2!1spl!2spl"
                allowFullScreen=""
                title="Mapa"
              />
            </Tween>
          </ScrollTrigger>
        </div>
      </div>
      <ScrollTrigger {...SCROLL_TRIGGER_PROPS} trigger={`.${styles.rodoLink}`}>
        <Tween from={{ opacity: 0, y: 20 }} duration={2}>
          <Link href="/rodo" target="_blank" className={styles.rodoLink}>
            Klauzula informacyjna RODO
          </Link>
        </Tween>
      </ScrollTrigger>
    </section>
  );
}
