import { faFacebookSquare, faLinkedin, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { Tween } from 'react-gsap';
import { Helmet } from 'react-helmet';
import Button from '../../../components/Button';

import { scrollToElement } from '../../../utils/scrollToElement';
import * as styles from './styles.module.scss';

const liTweenProps = { from: { opacity: 0, y: -30 }, duration: 1 };
const socialIconTweenProps = { from: { opacity: 0, y: 30 }, duration: 1 };

export default function HeroSection() {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const handleNavLinkClick = sectionId => {
    setIsMenuOpened(false);
    scrollToElement(sectionId);
  };

  return (
    <header className={styles.header}>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Work+Sans:200,300,400,600,900&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Changa+One&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className="blueOpacity">
        <div className="wrapper">
          <div
            className={classNames(styles.menuButton, isMenuOpened ? styles.closeMenuButton : '')}
            onClick={() => setIsMenuOpened(s => !s)}>
            <div className={styles.menuButtonDot} />
            <div className={styles.menuButtonDot} />
            <div className={styles.menuButtonDot} />
          </div>
          <nav className={isMenuOpened ? styles.openedMenu : ''}>
            <ul>
              <Tween {...liTweenProps}>
                <li onClick={() => handleNavLinkClick('aboutMe')}>O mnie</li>
              </Tween>
              <Tween {...liTweenProps} delay={0.2}>
                <li onClick={() => handleNavLinkClick('treatment')}>Leczenie</li>
              </Tween>
              <Tween {...liTweenProps} delay={0.4}>
                <li onClick={() => handleNavLinkClick('prices')}>Cennik</li>
              </Tween>
              <Tween {...liTweenProps} delay={0.6}>
                <li onClick={() => handleNavLinkClick('contact')}>Kontakt</li>
              </Tween>
            </ul>
          </nav>

          <Tween from={{ opacity: 0, y: -50 }} duration={2}>
            <div className={styles.heroLogoContainer}>
              <div className={styles.headerLogoDiv}>
                <div className={styles.logo}>
                  <StaticImage
                    src="../../../images/whitelogo.png"
                    alt="Fizjoterapia Robert Biernacki"
                    title="Fizjoterapia Robert Biernacki"
                  />
                </div>
                <div>
                  <div>
                    <h1>Fizjoterapia</h1>
                    <span className={styles.logoSmallText}>Robert Biernacki</span>
                  </div>
                </div>
              </div>
              <a href="https://rejestracja.medfile.pl/register/index/?uuid=fc882a2f-5ed7-3161-6a4e-b5c28d137746" target='_blank'><Button variant="white" className={styles.ctaButton}>Umów się na wizytę</Button></a>
            </div>
          </Tween>

          <div className={styles.readMoreButton}>
            <Tween {...socialIconTweenProps}>
              <a
                href="https://pl-pl.facebook.com/pages/category/Physical-Therapist/Fizjoterapia-Robert-Biernacki-2147867395435152/"
                target="_blank"
                alt="facebook"
                rel="noreferrer">
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
            </Tween>
            <Tween {...socialIconTweenProps} delay={0.2}>
              <a
                href="https://twitter.com/rober92782069"
                target="_blank"
                alt="twitter"
                rel="noreferrer">
                <FontAwesomeIcon icon={faTwitterSquare} />
              </a>
            </Tween>
            <Tween {...socialIconTweenProps} delay={0.4}>
              <a
                href="https://pl.linkedin.com/in/robert-biernacki-02191980?trk=people-guest_profile-result-card_result-card_full-click"
                target="_blank"
                rel="noreferrer">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </Tween>
          </div>
        </div>
      </div>
    </header>
  );
}
