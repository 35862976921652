import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { ScrollTrigger, Tween } from 'react-gsap';

import * as styles from './styles.module.scss';

export default function TreatmentSection() {
  const [showMore, setShowMore] = useState(false);

  return (
    <section id="treatment" className={styles.treatmentSection}>
      <div className="wrapper">
        <ScrollTrigger start="top center" end="top center" scrub={2} trigger=".treatmentHeader">
          <Tween from={{ opacity: 0, y: -20 }} duration={2}>
            <p className={classnames('biggerText', 'treatmentHeader')}>
              Leczę za pomocą metody <strong>McKenziego</strong> oraz <strong>PNF</strong>
            </p>
          </Tween>
        </ScrollTrigger>
        <div className={styles.methodsDescriptionWrapper}>
          <ScrollTrigger
            start="top center"
            end="top center"
            scrub={2}
            trigger={`.${styles.methodsImageDiv}`}>
            <Tween from={{ opacity: 0, x: 120 }} duration={3}>
              <div className={styles.methodsImageDiv}>
                <StaticImage src="../../../images/methods-img.jpg" alt="Leczenie" />
              </div>
            </Tween>
          </ScrollTrigger>
          <ScrollTrigger
            start="top center"
            end="top center"
            scrub={2}
            trigger={`.${styles.methodsTextDiv}`}>
            <Tween from={{ opacity: 0, x: -120 }} duration={3}>
              <div className={styles.methodsTextDiv}>
                <p className={styles.methodsListTitle}>Zalety leczenia powyższymi metodami:</p>
                <ul>
                  <li>dokładna diagnoza</li>
                  <li>wysoki poziom bezpieczeństwa</li>
                  <li>edukacja pacjenta</li>
                  <li>niskie wymagania sprzętowe</li>
                  <li>niskie koszty leczenia</li>
                </ul>
              </div>
            </Tween>
          </ScrollTrigger>
        </div>
        <div className={styles.moreDescriptionWrapper}>
          {showMore && (
            <div className={styles.moreDescriptionGrid}>
              <div className={styles.treatmentMethodDescription}>
                <h2>McKenzie</h2>
                <p>
                  Jest to metoda diagnostyczna i terapeutyczna dolegliwości bólowych kręgosłupa oraz
                  stawów obwodowych.
                </p>
                <p>
                  Terapia w metodzie McKenziego wykorzystuje czynniki mechaniczne, generowane siłami
                  samego pacjenta (indywidualnie dobrane ćwiczenia, pozycja, postawa) i szeroką gamę
                  technik manualnych.
                </p>
                <h3>Korzyści:</h3>
                <ul>
                  <li>
                    <b>Dokładna diagnoza</b> dająca możliwość szybkiej selekcji pacjentów ze względu
                    na tło bólu {'(mechaniczne lub "nie mechaniczne")'}
                  </li>
                  <li>
                    <b>Indywidualne podejście do terapii</b> zależne od rodzaju zespołu bólowego
                  </li>
                  <li>
                    <b>Wysoki poziom bezpieczeństwa metody</b> dzięki odpowiedniemu dopasowaniu
                    ruchów kręgosłupa podczas leczenia
                  </li>
                  <li>
                    <b>Niskie koszty</b> diagnostyki i leczenia
                  </li>
                  <li>
                    <b>Edukacja pacjenta</b> jako główny aspekt profilaktyczny
                  </li>
                </ul>
              </div>
              <div className={styles.treatmentMethodDescription}>
                <h2>PNF</h2>
                <p>
                  <b>PNF</b> (Prioprioceptive Neuromuscular Facilitation). Według filozofii tej
                  metody, proponowana pacjentowi terapia ma być bezbolesna i funkcjonalna, to znaczy
                  zgodna z potrzebami ruchowymi zgłaszanymi przez chorego i wzorowana na ruchach
                  zdrowego człowieka
                </p>
                <h3>Korzyści:</h3>
                <ul>
                  <li>
                    <b>Metoda przyjazna dla pacjenta</b> oparta na bezbolesnej pracy,
                    wykorzystującej silne odcinki ciałą i umiejętności chorego
                  </li>
                  <li>
                    <b>Wysoki poziom bezpieczeństwa terapii</b> wynikający z integracji zabiegu z
                    diagnostyką
                  </li>
                  <li>
                    <b>Możliwość pracy z chorym na każdym poziomie dysfunkcji</b> zabezpieczenie
                    terapią wszelkich funkcji od ruchowych do wegetatywnych
                  </li>
                  <li>
                    <b>Wysoki poziom edukacji pacjenta</b> oparty na współuczestnictwie w planowaniu
                    terapii oraz kontynuacji ćwiczeń w formie programu domowego
                  </li>
                  <li>
                    <b>Niewielkie wymagania sprzętowe</b>
                  </li>
                </ul>
              </div>
            </div>
          )}
          <button
            className={classnames(styles.showMoreButton, showMore ? styles.hideMoreInfo : '')}
            onClick={() => setShowMore(s => !s)}>
            {showMore ? 'Ukryj' : 'Dowiedz się więcej'}
            <FontAwesomeIcon icon={faAngleDown} />
          </button>
        </div>
      </div>
    </section>
  );
}
