import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './styles.module.scss';

export default function PriceTile({ className, price, title, description }) {
  return (
    <div className={classnames(className, styles.priceTile)}>
      <div className={styles.priceTileDescription}>
        <h3>{title}</h3>
        <ul>
          {description.map((d, i) => (
            <li key={i}>{d}</li>
          ))}
        </ul>
      </div>
      <div className={styles.priceTilePrice}>{price}</div>
    </div>
  );
}

PriceTile.propTypes = {
  className: PropTypes.string,
  price: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.array,
};

PriceTile.defaultProps = {
  className: '',
  price: '',
  title: '',
  description: [],
};
