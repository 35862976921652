import React from 'react';

import { scrollToElement } from '../../../utils/scrollToElement';
import * as styles from './styles.module.scss';

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className="wrapper">
        <ul>
          <li onClick={() => scrollToElement('aboutMe')}>O mnie</li>
          <li onClick={() => scrollToElement('treatment')}>Leczenie</li>
          <li onClick={() => scrollToElement('prices')}>Cennik</li>
          <li onClick={() => scrollToElement('contact')}>Kontakt</li>
        </ul>
        <a href="http://www.leczeniekregoslupa.org" className={styles.pageLink}>
          www.leczeniekregoslupa.org
        </a>
        <p className={styles.smallerText}>&copy; Wszelkie prawa zastrzeżone</p>
        <p className={styles.smallerText}>{new Date().getFullYear()}</p>
      </div>
    </footer>
  );
}
