import React from 'react';
import { ScrollTrigger, Tween } from 'react-gsap';
import Button from '../../../components/Button';

import PriceTile from '../../../components/PriceTile';
import * as styles from './styles.module.scss';

const SCROLL_TRIGGER_PROPS = {
  start: 'top center',
  end: 'top center',
  scrub: 2,
  trigger: '.pricesWrapper',
};

export default function PricesSection() {
  return (
    <section id="prices" className={styles.pricesSection}>
      <div className="blueOpacity">
        <div className="wrapper pricesWrapper">
          <ScrollTrigger {...SCROLL_TRIGGER_PROPS}>
            <Tween from={{ opacity: 0, y: -20 }} duration={3}>
              <h2>Cennik</h2>
            </Tween>
          </ScrollTrigger>
          <div className={styles.pricesTilesWrapper}>
            <ScrollTrigger {...SCROLL_TRIGGER_PROPS}>
              <Tween from={{ opacity: 0, y: -30 }} duration={3} delay={0.5}>
                <div>
                  <PriceTile
                    title="Pierwsza wizyta"
                    description={[
                      'Poznasz pełną diagnozę',
                      'Przedstawię Ci plan leczenia',
                      'Dowiesz się jak ćwiczyć',
                    ]}
                    price="170 zł"
                  />
                </div>
              </Tween>
            </ScrollTrigger>
            <ScrollTrigger {...SCROLL_TRIGGER_PROPS}>
              <Tween from={{ opacity: 0, y: -30 }} duration={3} delay={1}>
                <div>
                  <PriceTile
                    title="Wizyta kontrolna"
                    description={[
                      'Sprawdzimy postępy w leczeniu',
                      'W razie potrzeby zmienimy plan',
                      'Poznasz nowe ćwiczenia',
                    ]}
                    price="130 zł"
                  />
                </div>
              </Tween>
            </ScrollTrigger>
            <ScrollTrigger {...SCROLL_TRIGGER_PROPS}>
              <Tween from={{ opacity: 0, y: -30 }} duration={3} delay={1.5}>
                <div>
                  <PriceTile
                    title="Wizyta domowa"
                    description={[
                      'Przyjadę do Ciebie',
                      'Rozpoczniemy lub kontynuujemy proces leczenia',
                    ]}
                    price="200 zł"
                  />
                </div>
              </Tween>
            </ScrollTrigger>
          </div>
          <a href="https://rejestracja.medfile.pl/register/index/?uuid=fc882a2f-5ed7-3161-6a4e-b5c28d137746" target='_blank'><Button variant="white" className={styles.ctaButton}>Umów się na wizytę</Button></a>
        </div>
      </div>
    </section>
  );
}
