import '../styles/_global.scss';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import * as React from 'react';

import Seo from '../components/seo';
import AboutMeSection from '../sections/Home/AboutMeSection';
import ContactSection from '../sections/Home/ContactSection';
import ExperienceSection from '../sections/Home/ExperienceSection';
import Footer from '../sections/Home/Footer';
import HeroSection from '../sections/Home/HeroSection';
import PricesSection from '../sections/Home/PricesSection';
import TreatmentSection from '../sections/Home/TreatmentSection';

gsap.registerPlugin(ScrollTrigger);

export default function IndexPage() {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Seo />
      <HeroSection />
      <AboutMeSection />
      <ExperienceSection />
      <TreatmentSection />
      <PricesSection />
      <ContactSection />
      <Footer />
    </>
  );
}
