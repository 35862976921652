import classnames from 'classnames';
import React from 'react';
import { ScrollTrigger, Tween } from 'react-gsap';

import * as styles from './styles.module.scss';

const EDUCATION_YEARS = Number(new Date().getFullYear()) - 1995;
const EXPERIENCE_YEARS = Number(new Date().getFullYear()) - 1998;
const NUM_OF_CERTIFICATES = 30;

const SCROLL_TRIGGER_PROPS = triggerClassName => ({
  start: 'top center',
  end: 'top center',
  trigger: `.${triggerClassName}`,
  scrub: 2,
});

export default function ExperienceSection() {
  return (
    <section id="experience" className={styles.experienceSection}>
      <div className="blueOpacity">
        <div className={styles.experienceInfoWrapper}>
          <ScrollTrigger {...SCROLL_TRIGGER_PROPS('educationYearsNumber')}>
            <Tween from={{ opacity: 0, y: -20 }} duration={3}>
              <div className={styles.experienceTile}>
                <p>ponad</p>
                <Tween to={{ count: EDUCATION_YEARS, ease: 'none' }} duration={3}>
                  <p className={classnames(styles.bigNumber, 'educationYearsNumber')}>0</p>
                </Tween>
                <p>lat edukacji w&nbsp;zakresie fizjoterapii</p>
              </div>
            </Tween>
          </ScrollTrigger>
          <ScrollTrigger {...SCROLL_TRIGGER_PROPS('experienceYearsNumber')}>
            <Tween from={{ opacity: 0, y: -20 }} duration={3}>
              <div className={styles.experienceTile}>
                <p>ponad</p>
                <Tween to={{ count: EXPERIENCE_YEARS, ease: 'none' }} duration={3}>
                  <p className={classnames(styles.bigNumber, 'experienceYearsNumber')}>0</p>
                </Tween>
                <p>lat doświadczenia zawodowego</p>
              </div>
            </Tween>
          </ScrollTrigger>
          <ScrollTrigger {...SCROLL_TRIGGER_PROPS('certificatesNumber')}>
            <Tween from={{ opacity: 0, y: -20 }} duration={3}>
              <div className={styles.experienceTile}>
                <p>ponad</p>
                <Tween to={{ count: NUM_OF_CERTIFICATES, ease: 'none' }} duration={3}>
                  <p className={classnames(styles.bigNumber, 'certificatesNumber')}>0</p>
                </Tween>
                <p>zdobytych certyfikatów i&nbsp;dyplomów potwierdzających kwalifikacje</p>
              </div>
            </Tween>
          </ScrollTrigger>
        </div>
      </div>
    </section>
  );
}
