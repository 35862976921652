import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { ScrollTrigger, Tween } from 'react-gsap';

import * as styles from './styles.module.scss';

export default function AboutMeSection() {
  return (
    <section id="aboutMe" className={styles.aboutMe}>
      <div className="wrapper">
        <div className={styles.profileWrapper}>
          <ScrollTrigger
            start="top center"
            end="top center"
            scrub={2}
            trigger={`.${styles.profilePhotoWrapper}`}>
            <Tween
              from={{
                opacity: 0,
                x: -100,
              }}>
              <div className={styles.profilePhotoWrapper}>
                <StaticImage
                  src="../../../images/ProfileRB.png"
                  alt="Robert Biernacki"
                  title="Robert Biernacki"
                  className={styles.profilePhoto}
                />
              </div>
            </Tween>
          </ScrollTrigger>

          <ScrollTrigger
            start="top center"
            end="top center"
            scrub={2}
            trigger={`.${styles.profileTextDiv}`}>
            <Tween
              from={{
                opacity: 0,
                x: 100,
              }}>
              <div className={styles.profileTextDiv}>
                <p>Witam, nazywam się</p>
                <h2>Robert Biernacki</h2>
                <p>
                  Jestem <strong>certyfikowanym fizjoterapeutą</strong> Międzynarodowego Instytutu
                  McKenziego oraz <strong>dyplomowanym fizjoterapeutą</strong> PNF
                </p>
              </div>
            </Tween>
          </ScrollTrigger>
        </div>
      </div>
    </section>
  );
}
